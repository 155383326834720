
<template>
    <div>
        <b-tabs lazy>
            <b-tab title="Upcoming Events" @click="handleChangeTab('Pending')"
                   :active="tab === 'Pending'">
                <pending-list :booking_type="'Pending'"></pending-list>
            </b-tab>
            <b-tab title="Postpone Events" @click="handleChangeTab('Postpone')"
                   :active="tab === 'Postpone'">
                <pending-list :booking_type="'Postpone'"></pending-list>
            </b-tab>
            <b-tab title="Completed Events" @click="handleChangeTab('Complete')"
                   :active="tab === 'Complete'">
                <pending-list :booking_type="'Complete'"></pending-list>
            </b-tab>
            <b-tab title="Cancelled Events" @click="handleChangeTab('Cancelled')"
                   :active="tab === 'Cancelled'">
                <pending-list :booking_type="'Cancelled'"></pending-list>
            </b-tab>
            <b-tab title="On Hold" @click="handleChangeTab('Hold')"
                   :active="tab === 'Hold'">
                <pending-list :booking_type="'Hold'"></pending-list>
            </b-tab>
            <b-tab title="Tentative" @click="handleChangeTab('Tentative')"
                   :active="tab === 'Tentative'">
                <pending-list :booking_type="'Tentative'"></pending-list>
            </b-tab>
        </b-tabs>
    </div>
</template>


<script>
import PendingList from "./list";

export default {
    components: {
        PendingList,
    },
    data() {
        return {
            tab: 'Pending',
        };
    },
    methods: {
        handleChangeTab(type) {
            this.tab = type;
        }
    }
};
</script>
