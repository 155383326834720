<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard v-if="operation === null">
          <template v-slot:title>
            {{
              operation != null ?
                ( operation === 'add' ? 'Add Booking' : ( operation === 'detail' ? 'Booking Detail'
                  : 'Edit Booking' ) ) : operationTitle
            }}
            <p><small>View, Edit, Delete Bookings.</small></p>
          </template>
          <template v-slot:toolbar>
            <b-input-group-append>

              <b-dropdown id="dropdown-1" variant="light-primary" class="text-white font-weight-bolder">

                <template v-slot:button-content>
                  <i class="ki ki-bold-menu"></i> Reports
                </template>
                <b-dropdown-item @click="report('bookings')">Booking</b-dropdown-item>
                <b-dropdown-item @click="report('functions')">Function</b-dropdown-item>
                <b-dropdown-item @click="report('halls')">Hall</b-dropdown-item>
                <b-dropdown-item v-if="has_rooms" @click="report('rooms')">Room</b-dropdown-item>
              </b-dropdown>

              <b-button
                :class="checkFilterCount ? `btn btn-danger text-white font-weight-bolder ml-2` : 'btn btn-light-primary font-weight-bolder ml-2'"
                v-b-toggle.sidebar-backdrop-quotations>
                <i class="fa fa-filter fa-sm"></i> Filter
              </b-button>

            </b-input-group-append>
          </template>
          <template v-slot:body>
            <v-card>
              <div class="w-100 mb-6">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="dataTables_length"><label>Show
                      <b-form-select :options="[10, 50, 100]"
                                     class="datatable-select"
                                     size="sm"
                                     v-model="pagination.totalPerPage"></b-form-select>
                      entries</label></div>

                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="dataTables_filter" id="kt_datatable_filter"><label>Search:
                      <b-form-input class="form-control form-control-sm ml-2 w-25"
                                    label="Search"
                                    type="search"
                                    v-model="search"
                                    v-on:keyup.enter="handleSearch"></b-form-input>
                    </label>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
            <div class="quotations-table"
                 v-if="$global.hasPermission('quotationbookingsview') && operation === null">

              <v-data-table
                :headers="columns"
                :items="dataSource"
                :items-per-page="parseInt(pagination.totalPerPage)"
                loading-text="Loading... Please wait"
                class="table table-head-custom table-vertical-center table-responsive"
                hide-default-footer
                ref="table"
                responsive
                :sort-by.sync="listQueryParams.sortBy"
                :sort-desc.sync="listQueryParams.sortDesc"
              >
                <template v-slot:item.id="record">
                  {{ ( pagination.current - 1 ) * pagination.totalPerPage + ( record.index + 1 ) }}
                </template>
                <template v-slot:item.leads="{item}">
                  <span class="text-decoration-underline cursor-pointer" @click="redirectToRouteLink(`bookings/edit/${item.id}`)" size="sm">
                    {{ item.leads ? item.leads.label : "" }}
                  </span>
                </template>
                <template v-slot:item.sub_total="{item}">
                  <p>
                    {{
                      $global.numberToLocationString(totalEventAmount(item.quotationEvents).amount
                        +
                        totalRoomAmount(item.quotationRooms).amount)
                    }}</p>
                </template>
                <template v-slot:item.gst_amount="{item}">
                  <p>
                    {{
                      $global.numberToLocationString(totalEventAmount(item.quotationEvents).gstAmount
                        +
                        totalRoomAmount(item.quotationRooms).gstAmount)
                    }}</p>
                </template>
                <template v-slot:item.total="{item}">
                  <p>
                    {{
                      $global.numberToLocationString(totalEventAmount(item.quotationEvents).total
                        +
                        totalRoomAmount(item.quotationRooms).total)
                    }}</p>
                </template>
                <template v-slot:item.paid="{item}">
                  <p>
                    {{
                      $global.numberToLocationString(item.financial_total.paid_amount)
                    }}</p>
                </template>
                <template v-slot:item.due_amount="{item}">
                  <p>{{
                      item.financial_total ?
                        $global.numberToLocationString(
                          ( totalRoomAmount(item.quotationRooms).total +
                            totalEventAmount(item.quotationEvents).total )
                          - item.financial_total.paid_amount) : 0
                    }}</p>
                </template>
                <template v-slot:item.due_date="{item}">

                  <p v-if="(item.financial_total &&
                                        ((totalRoomAmount(item.quotationRooms).total +
                                        totalEventAmount(item.quotationEvents).total)
                                        - item.financial_total.paid_amount) > 0)">
                    {{
                      item.financial_total ?
                        $global.dateFormat(item.financial_total.due_date) : ''
                    }}
                  </p>
                </template>
                <template v-slot:item.start_date="{item}">
                  {{ item.start_date ? $global.dateFormat(item.start_date) : "" }} <br /> {{ item.end_date ? $global.dateFormat(item.end_date) : "" }}
                </template>
                <template v-slot:item.created_at="{item}">
                  {{ $global.dateFormat(item.created_at) }}
                </template>
                <template class="action-column" v-slot:item.action="{item}">
                  <div>
                    <b-dropdown no-caret size="sm" toggle-class="text-decoration-none"
                                variant="link">
                      <template #button-content>
                        <p class="btn btn-icon btn-light btn-hover-primary btn-sm">
                                                 <span
                                                   class="svg-icon svg-icon-md svg-icon-primary m-0">
                                                  <!--begin::Svg Icon-->
                                                  <inline-svg
                                                    class="action-edit-button"
                                                    src="/media/svg/icons/General/Settings-1.svg"
                                                  ></inline-svg>
                                                   <!--end::Svg Icon-->
                                                </span>
                        </p>
                      </template>
                      <b-dropdown-item
                        @click="redirectToRouteLink(`bookings/edit/${item.id}`)"
                        v-show="$global.hasPermission('quotationbookingsupdate')">
                                                <span
                                                  class="pt-1 pb-1"
                                                  size="sm">
                                                        <i class="fa fa-edit mr-2"></i> Edit
                                                </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="handleSubmitOperation(item.id,0)" href="#"
                                       v-show="[1, 2, 3, 4, 6].includes(item.status) && $global.hasPermission('quotationbookingsdelete')">
                                                <span class="pt-1 pb-1" size="sm">
                                                    <i class="fa fa-history mr-2"></i> Upcoming
                                                </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="handleSubmitOperation(item.id,2)" href="#"
                                       v-show="[0, 1, 4, 6].includes(item.status) && $global.hasPermission('quotationbookingsdelete')">
                                                <span class="pt-1 pb-1" size="sm">
                                                    <i class="fa fa-clock-four mr-2"></i> Postponed
                                                </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="handleSubmitOperation(item.id,4)" href="#"
                                       v-show="[0, 2].includes(item.status) && $global.hasPermission('quotationbookingsdelete')">
                                                <span class="pt-1 pb-1" size="sm">
                                                    <i class="fa fa-pause mr-2"></i> Hold
                                                </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="handleSubmitOperation(item.id,0)" href="#"
                                       v-show="[5].includes(item.status) && $global.hasPermission('quotationbookingsdelete')">
                                                <span class="pt-1 pb-1" size="sm">
                                                    <i class="fa fa-pause mr-2"></i> Confirm
                                                </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="handleSubmitOperation(item.id,3)" href="#"
                                       v-show="[0, 2].includes(item.status) && $global.hasPermission('quotationbookingsdelete')">
                                                <span class="pt-1 pb-1" size="sm">
                                                    <i class="fa fa-cancel mr-2"></i> Cancel
                                                </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="handleDeleteOperation(item.id)" href="#"
                                       v-show="item.status !== 1 && $global.hasPermission('quotationbookingsdelete')">
                                                <span class="pt-1 pb-1" size="sm">
                                                    <i class="fa fa-trash mr-2"></i> Delete
                                                </span>
                      </b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown no-caret size="sm" toggle-class="text-decoration-none" variant="link">
                      <template #button-content>
                        <p class="btn btn-icon btn-light btn-hover-primary btn-sm ml-2">
                      <span aria-label="Share" class="svg-icon svg-icon-md svg-icon-primary m-0">
                        <inline-svg aria-label="Share" class="action-edit-button" src="/media/svg/files/pdf.svg"/>
                      </span>
                        </p>
                      </template>
                      <b-dropdown-item @click="downloadPdf(item.id)" href="#"
                                       v-if="$global.hasPermission('quotationbookingsupdate')">
                        Office copy
                      </b-dropdown-item>
                      <b-dropdown-item @click="downloadPdf(item.id, 'office-food-menu')" href="#"
                                       v-if="$global.hasPermission('quotationbookingsupdate')">
                        Office copy - Food menu
                      </b-dropdown-item>
                      <b-dropdown-item @click="downloadPdf(item.id, 'office-food-menu', 'gu')" href="#"
                                       v-if="$global.hasPermission('quotationbookingsupdate')">
                        Office copy - GJ Food menu
                      </b-dropdown-item>
                      <b-dropdown-item @click="downloadPdf(item.id, 'summary')" href="#"
                                       v-if="$global.hasPermission('quotationbookingsupdate')">
                        Summary
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="downloaCustomerdPdf(item.id,'with_prices','download')" href="#"
                        v-if="$global.hasPermission('quotationbookingsupdate')">
                        With Breakup
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="downloaCustomerdPdf(item.id,'without_prices','download')"
                        href="#"
                        v-if="$global.hasPermission('quotationbookingsupdate')">
                        Without Breakup
                      </b-dropdown-item>
                      <b-dropdown-item @click="downloaCustomerdPdf(item.id,'food-menu','download')"
                                       href="#"
                                       v-if="$global.hasPermission('quotationbookingsupdate')">
                        Food menu
                      </b-dropdown-item>
                      <b-dropdown-item @click="downloaCustomerdPdf(item.id,'decor','download')"
                                       href="#"
                                       v-if="$global.hasPermission('quotationbookingsupdate')">
                        Decor
                      </b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown no-caret size="sm" toggle-class="text-decoration-none" variant="link">
                      <template #button-content>
                        <p class="btn btn-icon btn-light btn-hover-primary btn-sm ml-2">
                       <span
                         aria-label="Share"
                         class="svg-icon svg-icon-md svg-icon-primary m-0">
                         <inline-svg
                           aria-label="Share"
                           class="action-edit-button"
                           src="/media/svg/icons/Communication/whatsapp.svg" />
                      </span>
                        </p>
                      </template>
                      <b-dropdown-item
                        @click="sendConfirmation(item.id)" href="#">
                        Send confirmation
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="downloaCustomerdPdf(item.id,'with_prices','booking')" href="#">
                        With Breakup
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="downloaCustomerdPdf(item.id,'without_prices','booking')"
                        href="#">
                        Without Breakup
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="downloaCustomerdPdf(item.id,'without_prices','booking')"
                        href="#">
                        Food menu
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </v-data-table>
              <div class="row">
                <div class="pagination-box">
                  <b-pagination
                    :per-page="pagination.totalPerPage"
                    :total-rows="pagination.total"
                    size="lg"
                    v-model="pagination.current"
                  ></b-pagination>

                </div>
              </div><!-- /.pull-right -->
            </div>
          </template>
        </KTCard>
        <template>
          <div>
            <b-sidebar
              :backdrop-variant="`transparent`"
              backdrop
              id="sidebar-backdrop-quotations"
              right
              shadow
              title="Filters"
            >
              <template #header="{ hide }">
                <b-col sm="10">
                  <strong id="sidebar-backdrop-leads___title__">Filters</strong>
                </b-col>
                <b-col sm="2">
                  <button @click="hide();"
                          class="btn btn-xs btn-icon btn-light btn-hover-primary text-right">
                    <i class="ki ki-close icon-xs text-muted"></i>
                  </button>
                </b-col>
              </template>
              <template #header="{ hide }">
                <b-col sm="10">
                  <strong id="sidebar-backdrop-bookings___title__">Filters</strong>
                </b-col>
                <b-col sm="2">
                  <button @click="hide();"
                          class="btn btn-xs btn-icon btn-light btn-hover-primary text-right">
                    <i class="ki ki-close icon-xs text-muted"></i>
                  </button>
                </b-col>
              </template>
              <template #footer="{ hide }">
                <div
                  class="d-flex justify-center cursor-pointer w-full border-top align-items-center px-3 py-4">
                  <b-button @click="hide();handleResetFilterClick()"
                            class="btn btn-light-primary font-weight-bolder ml-2">
                    <i class="fa fa-broom fa-sm"></i> Clear All
                  </b-button>
                </div>
              </template>
              <div class="px-10 py-4">
                <b-col sm="12">
                  <b-form-group
                    label="Start Date"
                    label-for="from_date">
                    <b-form-datepicker
                      :date-disabled-fn="dateDisabled"
                      :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                      close-button
                      reset-button
                      today-button
                      v-model="filters.from_date"
                    >
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="End Date"
                    label-for="to_date">
                    <b-form-datepicker
                      :date-disabled-fn="dateDisabled"
                      :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                      :disabled="!filters.from_date"
                      close-button
                      reset-button
                      today-button
                      v-model="filters.to_date"
                    >
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Created from"
                    label-for="created_from">
                    <b-form-datepicker
                      :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                      close-button
                      reset-button
                      today-button
                      v-model="filters.created_from"
                    >
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Created to"
                    label-for="created_to">
                    <b-form-datepicker
                      :date-disabled-fn="dateDisabled(filters.created_to)"
                      :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                      :disabled="!filters.created_from"
                      close-button
                      reset-button
                      today-button
                      v-model="filters.created_to"
                    >
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Event Owner"
                    label-for="filter-lead-owner">
                    <treeselect
                      :options="dropdowns.subUsers"
                      class="mb-2"
                      id="filter-lead-owner"
                      v-model="filters.event_owner"></treeselect>
                  </b-form-group>
                </b-col><!--/b-col-->
                <b-col sm="12">
                  <b-form-group
                    label="Hall"
                    label-for="halls">
                    <treeselect
                      :multiple="true"
                      :options="dropdowns.halls"
                      v-model="filters.halls">
                    </treeselect>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Event type"
                    label-for="event_type">
                    <treeselect
                      :multiple="true"
                      :options="dropdowns.eventType"
                      v-model="filters.event_type"
                    >
                    </treeselect>
                  </b-form-group>
                </b-col><!--/b-col-->
                <b-col sm="12">
                  <b-form-group
                    label="Sources"
                    label-for="sources">
                    <treeselect
                      :multiple="true"
                      :options="dropdowns.sources"
                      v-model="filters.sources"
                    >
                    </treeselect>
                  </b-form-group>
                </b-col>
              </div>
            </b-sidebar>
          </div>
        </template>
        <!-- /.filter-container -->
      </div>
    </div>
    <b-modal hide-footer id="quotation-event-details" size="xl" title="Quotation Event Details">
      <b-row>
        <b-col :key="index" sm="4" v-for="(data,index) in quotationEventDetail">
          <b-card
            align="center"
            border-variant="primary"
          >
            <b-card-header class="bg-primary text-white p-2">
              {{ data.event_type }}
            </b-card-header>
            <b-card-text class="p-10">
              <p class="text--darken-2 mb-2">{{ $global.dateFormat(data.from_date) }} -
                ({{ data.from_time }} - {{ data.to_time }})</p>
              <b-tag :key="index" v-for="(d,index) in data.halls">
                {{ d.name }}
              </b-tag>
              <p class="text--darken-2 mb-2 m-2">
                <strong>{{ data.total ? Number(data.total.total).toLocaleString() : 0 }}</strong>
              </p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col v-if="!quotationEventDetail.length">
          <h4>No data available</h4>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import KTCard from "@/view/content/Card.vue";
import ListingMixin from "@/assets/js/components/ListingMixin";
import QuotationEventSummary from "../quotations/quotationEvents/summary/index.vue";
import handleMatchDataMixin from "./handleMatchDataMixin";
import { bus } from "@/main";
import totalAmountMixin from "../../../assets/js/components/totalAmountMixin";
import handleError from "@/util/handleError";
import ModuleMixin from "./module.mixin";
import {request} from "@/core/services/Request";

const DEFAULT_FILTER_STATE = {
  from_date: null,
  to_date: null,
  status: null
};

export default {
  props: ['booking_type'],
  mixins: [ListingMixin, handleMatchDataMixin, totalAmountMixin, handleError, ModuleMixin],
  data() {
    return {
      filters: { ...DEFAULT_FILTER_STATE },
      has_rooms: false
    };
  },
  components: {
    KTCard,
  },
  async created() {
    bus.$off("quotation-event-submit");
    bus.$off("quotation-room-submit");
    bus.$off("quotation-data-refresh");
    await this.getRooms()
    await this.getHalls();
    await this.getSources();
    await this.getEventType();
    await this.getSubUser();
  },
  methods: {
    handleResetFilterClick() {
      this.filters = { ...DEFAULT_FILTER_STATE };
      this.isFilterApplied = 'reset';
      this.checkFilterCountStatus();
    },

    async getRooms() {
      try {
        const response = await request({
          url: `/dropdowns/has_rooms`,
          method: 'post',
        });

        this.has_rooms = response.data.has_room;
      } catch (e) {

      }
    },
  },
  computed: {
    ...mapState([
      'global',
    ]),
    ...mapGetters({
      checkPendingEventData: 'checkPendingEventData',
      checkPendingRoomData: 'checkPendingRoomData',
      checkFinancialPendingData: 'checkFinancialPendingData',
    }),
  },
};
</script>

<style>
.Polaris-Button--primary {
  background-color: #3699FF !important;
}
</style>
